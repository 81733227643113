import { DEFAULT_PRODUCER_RECEIVING_DAYS } from 'src/constants/producers'
import type { Orders, Order, Distributor, ProducerReceivingDays } from 'src/types'
import { removeDuplicatedStrings } from './arrays/removeDuplicated'

const getDeliveryDateAsString = (order: Order) => {
  return typeof order.deliveryDate === 'string'
    ? order.deliveryDate
    : typeof order.deliveryDate === 'object'
      ? order.deliveryDate.toISOString()
      : ''
}

export default function getOrdersWithSuppliers(orders: Orders, distributor: Distributor | null) {
  return (
    orders
      ?.map((order) => {
        const supplier = distributor?.producers?.find((item) => item.phone === order.phone)
        const supplierName = supplier?.name ?? ''

        const receivingDays: ProducerReceivingDays[] = []

        const supplierReceivingDays = supplier?.receivingDays ?? DEFAULT_PRODUCER_RECEIVING_DAYS

        if (supplier) {
          receivingDays.push(...supplierReceivingDays)
        }

        const receivingDaysWithoutDuplicated = removeDuplicatedStrings(receivingDays)

        return {
          ...order,
          name: supplierName,
          producerCode: supplier?.code ?? '',
          receivingDays: receivingDaysWithoutDuplicated,
        }
      })
      .sort((order, nextOrder) => {
        // TODO: order.deliveryDate has typing inconsistencies, it's either stored as a `Date` object or as `string`
        const deliveryDate = getDeliveryDateAsString(order)
        const nextDeliveryDate = getDeliveryDateAsString(nextOrder)

        // NOTE: Sort recent orders by delivery date first
        return deliveryDate.localeCompare(nextDeliveryDate)
      }) || []
  )
}
