import { ActionIcon, Box, Divider, Flex, Text } from '@mantine/core'
import { DeliveryDate } from '../common/Inputs/DeliveryDate'
import { useDisclosure } from '@mantine/hooks'
import { IconPlus, IconTrash } from '@tabler/icons-react'
import { Fragment, useState } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { pendingNotificationFactory } from 'src/factories/pendingNotificationFactory'
import deleteOfferSku from 'src/requests/deleteOfferSku'
import DeleteModal from '../common/Modals/DeleteModal'
import AddSkuModal from './AddSkuModal'
import styles from './Chat.module.css'
import { Price } from './Price'
import { Volume } from './Volume'
import { Order, TCreateOffer } from 'src/types'
import { useMutation } from '@tanstack/react-query'
import createOffer from 'src/requests/createOffer'
import { useDistributor } from 'src/providers/Distributor'
import { useParams } from 'react-router-dom'

export interface SelectedOfferToDeleteData {
  sku: string
  offerId: string
  product: Order['products'][number]
}

export interface SelectedOfferToAddSkuData {
  deliveryDate: Date
}

type OffersProps = {
  listOffers: Order[]
  refreshOffers: (bool: boolean) => void
}

export const Offers = ({ listOffers, refreshOffers }: OffersProps) => {
  const [isDeleteModalOpen, { open: openDeleteModal, close: closeDeleteModal }] = useDisclosure(false)
  const [isAddSkuModalOpen, { open: openAddSkuModal, close: closeAddSkuModal }] = useDisclosure(false)
  const [deleteOfferData, setDeleteOfferData] = useState<SelectedOfferToDeleteData | null>(null)
  const [selectedOfferToAddSku, setSelectedOfferToAddSku] = useState<SelectedOfferToAddSkuData | null>(null)
  const { showBoundary } = useErrorBoundary()
  const mutation = useMutation({
    mutationFn: createOffer,
  })
  const { distributor } = useDistributor()
  const { phone } = useParams()

  async function handleAddSku(products: TCreateOffer['products']) {
    const notification = pendingNotificationFactory({
      pending: {
        title: 'Adicionando SKU...',
        message: 'Isso não deve demorar muito',
      },
      success: {
        title: 'SKU adicionado com sucesso!',
        message: 'Caso a lista não atualize, por favor, atualize manualmente',
      },
      error: {
        title: 'Erro ao adicionar SKU.',
        message: 'Por favor, verifique os dados, caso o problema persista, entre em contato com o administrador.',
      },
    })

    try {
      await mutation.mutateAsync({
        products: products,
        phone: phone!,
        distributorId: distributor!.distributorId,
        deliveryDate: selectedOfferToAddSku!.deliveryDate,
        changedBy: 'OPERATOR',
      })
      notification.success()
      refreshOffers(true)
      closeAddSkuModal()
    } catch (error) {
      notification.error()
      if (error instanceof Error) {
        console.error(error.message)
        showBoundary(error)
      } else {
        console.error(error)
        showBoundary(error)
      }
    }
  }

  const deleteOfferById = async () => {
    if (deleteOfferData) {
      const notification = pendingNotificationFactory({
        pending: {
          message: 'Isso não deve demorar muito',
          title: 'Removendo SKU...',
        },
        success: {
          message: 'Caso a lista não atualize, por favor, atualize manualmente',
          title: 'SKU removido com sucesso!',
        },
        error: {
          message: 'Por favor, verifique os dados, caso o problema persista, entre em contato com o administrador',
          title: 'Erro ao remover SKU.',
        },
      })

      try {
        await deleteOfferSku({
          sku: deleteOfferData.sku,
          offerId: deleteOfferData.offerId,
        })
        notification.success()
        refreshOffers(true)
        closeDeleteModal()
      } catch (error) {
        notification.error()
        if (error instanceof Error) {
          console.log(error.message)
          showBoundary(error)
        } else {
          console.log(error)
          showBoundary(error)
        }
      }
    }
  }

  const openDeleteModalFunc = (sku: string, offerId: string, product: Order['products'][number]) => {
    setDeleteOfferData({ sku, offerId, product })
    openDeleteModal()
  }

  const closeDeleteModalFunc = () => {
    setDeleteOfferData(null)
    closeDeleteModal()
  }

  const openAddSkuModalFunc = (deliveryDate: Date) => {
    setSelectedOfferToAddSku({ deliveryDate })
    openAddSkuModal()
  }

  const closeAddSkuModalFunc = () => {
    setSelectedOfferToAddSku(null)
    closeAddSkuModal()
  }

  return (
    <>
      <DeleteModal
        show={isDeleteModalOpen}
        title="Remover SKU"
        description="Tem certeza que deseja remover esse SKU?"
        onCloseModal={() => closeDeleteModalFunc()}
        handleDelete={() => deleteOfferById()}
      />
      <AddSkuModal
        show={isAddSkuModalOpen}
        onCloseModal={() => closeAddSkuModalFunc()}
        handleAdd={(products: TCreateOffer['products']) => handleAddSku(products)}
        producerPhone={phone!}
        selectedOfferToAddSku={selectedOfferToAddSku}
      />
      <Box className={styles.draft_offer_list}>
        {listOffers.flatMap((offer) => {
          const expirationDate = offer.validUntil

          return (
            <Fragment key={offer.id}>
              <Box className={styles.draft_offer} pos={'relative'}>
                <Flex align="center" justify="end"></Flex>
                <Text mt={2} size="sm" c="#818582" ta="center">
                  <DeliveryDate
                    setShouldRefetchOrders={refreshOffers}
                    orderId={offer.id}
                    deliveryDate={expirationDate ? expirationDate : offer.deliveryDate}
                    disableEdit={!!expirationDate}
                    valueFormat={expirationDate ? '[até] DD/MM, dddd' : 'DD/MM, dddd'}
                  />
                </Text>
                {offer.products
                  .sort((a, b) => a.sku.localeCompare(b.sku))
                  .map((product, index) => (
                    <Fragment key={`${offer.id}-${product.sku}`}>
                      <Divider my={'xs'} />
                      <Box key={index}>
                        <Box className={styles.draft_offer_sku}>{product.sku}</Box>
                        <Flex justify="space-between" px="sm">
                          <Volume setShouldRefetchOrders={refreshOffers} product={product} orderId={offer.id} />
                          <Price setShouldRefetchOrders={refreshOffers} product={product} orderId={offer.id} />
                          <ActionIcon
                            ml={4}
                            variant="transparent"
                            onClick={() => openDeleteModalFunc(product.sku, offer.id, product)}
                          >
                            <IconTrash size={21} color="red" />
                          </ActionIcon>
                        </Flex>
                      </Box>
                    </Fragment>
                  ))}
                <Divider my={'xs'} />
                <Flex justify="center" mb={4}>
                  <ActionIcon ml={4} onClick={() => openAddSkuModalFunc(offer.deliveryDate)}>
                    <IconPlus size={21} />
                  </ActionIcon>
                </Flex>
              </Box>
            </Fragment>
          )
        })}
        {!listOffers.length && (
          <Box mt={4} ta="center" opacity={0.5}>
            Sem oferta
          </Box>
        )}
      </Box>
    </>
  )
}
