import { ActionIcon, Card, Center, Flex, Space, Text } from '@mantine/core'
import { IconArrowNarrowLeft } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'
import { OfferForm } from './OfferForm'
import { useDistributor } from 'src/providers/Distributor'
import { TCreateOffer } from 'src/types'
import { useMutation } from '@tanstack/react-query'
import { useErrorBoundary } from 'react-error-boundary'
import { useOrders } from 'src/providers/Orders'
import { pendingNotificationFactory } from 'src/factories/pendingNotificationFactory'
import createOffer from 'src/requests/createOffer'

export function CreateOffer() {
  const { distributor } = useDistributor()
  const { setShouldRefetch } = useOrders()
  const navigate = useNavigate()
  const mutation = useMutation({
    mutationFn: createOffer,
  })
  const { showBoundary } = useErrorBoundary()

  async function handleSubmit(data: TCreateOffer) {
    const notification = pendingNotificationFactory({
      pending: {
        title: 'Criando oferta...',
        message: 'Isso não deve demorar muito',
      },
      success: {
        title: 'Oferta criada com sucesso!',
        message: 'Caso a lista não atualize, por favor, atualize manualmente',
      },
      error: {
        title: 'Erro ao criar oferta.',
        message: 'Por favor, verifique os dados, caso o problema persista, entre em contato com o administrador.',
      },
    })

    try {
      await mutation.mutateAsync({
        products: data.products,
        phone: data.phone,
        distributorId: distributor!.distributorId,
        deliveryDate: data.deliveryDate,
        changedBy: 'OPERATOR',
      })
      notification.success()
      setShouldRefetch(true)
      navigate('/ofertas/sku')
    } catch (error) {
      notification.error()
      if (error instanceof Error) {
        console.log(error.message)
        showBoundary(error)
      } else {
        console.log(error)
        showBoundary(error)
      }
    }
  }

  return (
    <Card
      padding="xl"
      shadow="md"
      radius="md"
      style={{
        overflow: 'visible',
      }}
    >
      <Flex mb="lg">
        <Center>
          <ActionIcon
            style={{
              border: 0,
            }}
            onClick={() => navigate(-1)}
            size={24}
            color="dark"
            variant="transparent"
            p={0}
            w="fit-content"
          >
            <IconArrowNarrowLeft size={24} />
          </ActionIcon>
          <Space w="sm" />
          <Text fw={500}>Criar oferta</Text>
        </Center>
      </Flex>
      <OfferForm onSubmit={handleSubmit} />
    </Card>
  )
}
