import { useMemo } from 'react'
import { useDistributor } from 'src/providers/Distributor'
import { removeDuplicatedArrayItems } from 'src/utils/arrays/removeDuplicated'

export function useProducers() {
  const { distributor } = useDistributor()

  const producers = useMemo(() => {
    return distributor?.producers || []
  }, [distributor?.producers])

  const labelValueProducers = useMemo(() => {
    const options = producers.map((producer) => ({
      value: producer.phone,
      label: producer.name,
    }))

    return removeDuplicatedArrayItems(options, 'value')
  }, [producers])

  return {
    producers,
    labelValueProducers,
  }
}
