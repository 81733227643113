import { ActionIcon, Button, Checkbox, Flex, Table } from '@mantine/core'
import { OnMultipleCheckboxChange } from 'src/hooks/useBulkCheckbox'
import { OrderBy, TOrderIcon, ToggleOrder } from 'src/hooks/useOrderBy'
import { useProducers } from 'src/hooks/useProducers'

export type ProducerTableHeadProps = {
  allOptionsSelected: boolean
  someOptionsSelected: boolean
  anyOptionSelected: boolean
  onMultipleCheckboxChange: OnMultipleCheckboxChange
  orderByDateStatus: OrderBy
  orderByLastOffer: OrderBy
  toggleOrderByDate: ToggleOrder
  toggleOrderByLastOffer: ToggleOrder
  OrderIcon: TOrderIcon
  LastOfferIcon: TOrderIcon
  currentSendingProducerPhone: string
  selectedOptions: string[]
  sendMessage: (phone: string) => Promise<void>
  successSentProducersPhones: string[]
}

export function CustomTableHead(props: ProducerTableHeadProps) {
  const { producers } = useProducers()

  const sendBulkMessages = async () => {
    const selectedRowsProducers = props.selectedOptions.map((phone) => {
      const producer = producers.find((producer) => producer.phone === phone)

      if (producer) return producer
    })

    for (const producer of selectedRowsProducers) {
      if (producer) {
        await props.sendMessage(producer.phone)
      }
    }

    props.onMultipleCheckboxChange()
  }

  return (
    <Table.Thead>
      <Table.Tr mih={60}>
        <Table.Th>
          <Checkbox
            aria-label="Select row"
            checked={props.allOptionsSelected}
            indeterminate={props.someOptionsSelected}
            onChange={props.onMultipleCheckboxChange}
          />
        </Table.Th>
        <Table.Th>Nome</Table.Th>
        <Table.Th>Telefone</Table.Th>
        <Table.Th ta="center">
          <Flex align="center" gap={8} justify="center">
            Ultima Solicitação
            <ActionIcon
              size="xs"
              variant={props.orderByDateStatus ? 'light' : 'transparent'}
              onClick={props.toggleOrderByDate}
            >
              <props.OrderIcon />
            </ActionIcon>
          </Flex>
        </Table.Th>
        <Table.Th ta="center">
          <Flex align="center" gap={8} justify="center">
            Última Oferta
            <ActionIcon
              size="xs"
              variant={props.orderByLastOffer ? 'light' : 'transparent'}
              onClick={props.toggleOrderByLastOffer}
            >
              <props.LastOfferIcon />
            </ActionIcon>
          </Flex>
        </Table.Th>
        <Table.Th colSpan={2}>
          <Flex justify="flex-end">
            <Button
              style={{
                pointerEvents: props.anyOptionSelected ? 'auto' : 'none',
              }}
              opacity={props.anyOptionSelected ? 1 : 0}
              onClick={sendBulkMessages}
              disabled={!!props.currentSendingProducerPhone}
              loading={!!props.currentSendingProducerPhone}
              loaderProps={{ type: 'dots' }}
            >
              {props.selectedOptions.length <= props.successSentProducersPhones.length
                ? 'Solicitado!'
                : 'Solicitar oferta(s) selecionada(s)'}
            </Button>
          </Flex>
        </Table.Th>
      </Table.Tr>
    </Table.Thead>
  )
}
