import { Table } from '@mantine/core'
import { ReceivedOffer } from 'src/types'
import { formatUTCDate } from 'src/utils/formatDate'

export type ReceivedOffersCustomTableBodyProps = {
  items: ReceivedOffer[]
}

export function CustomTableBody(props: ReceivedOffersCustomTableBodyProps) {
  return (
    <>
      <Table.Tbody>
        {props.items.map((offer) => {
          return (
            <Table.Tr key={`${offer.id}-$${offer.sku}`}>
              {/* 
              TODO: set BRT 
              */}
              <Table.Td>
                {offer.deliveryDate ? formatUTCDate(offer.deliveryDate, 'dd/MM/yyyy') : 'Sem data definida'}
              </Table.Td>
              <Table.Td>{offer.date}</Table.Td>
              <Table.Td>{offer.requester ? offer.requester.name : 'Não informado'}</Table.Td>
              <Table.Td>{offer.producer.name}</Table.Td>
              <Table.Td>{offer.producer.phone}</Table.Td>
              <Table.Td>{offer.sku}</Table.Td>
              <Table.Td>{offer.volume}</Table.Td>
              <Table.Td style={offer.priceExceedsFixedPrice ? { color: 'red' } : {}}>{offer.price}</Table.Td>
            </Table.Tr>
          )
        })}
      </Table.Tbody>
    </>
  )
}
