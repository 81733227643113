import { api } from 'src/services/api'
import { Order } from 'src/types'

type CreateOfferContext = {
  distributorId: string
  phone: string
  products: Order['products']
  deliveryDate: Date
  changedBy?: 'SUPPLIER' | 'OPERATOR'
}

export default async function createOffer({ phone, ...body }: CreateOfferContext) {
  await api.post(`/producers/${phone}/offers`, body)
}
