import {
  ActionIcon,
  Box,
  Button,
  Card,
  Center,
  Collapse,
  Flex,
  Grid,
  Input,
  MultiSelect,
  Space,
  Text,
  Title,
} from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { useDisclosure, useWindowScroll } from '@mantine/hooks'
import { IconFilter, IconSearch } from '@tabler/icons-react'
import { parseISO } from 'date-fns'
import { useMemo, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useCategories } from 'src/hooks/useCategories'
import { useFilterArray } from 'src/hooks/useFilterArray'
import { useProducers } from 'src/hooks/useProducers'
import { useQueryParams } from 'src/hooks/useQueryParams'
import { useSKUs } from 'src/hooks/useSKUs'
import { removeDuplicatedValuesFromArray } from 'src/utils/removeDuplicatedValuesFromArray'
import { CustomTable } from './Table'
import styles from '../Producer.module.css'

export default function ProducersTable() {
  const { producers } = useProducers()
  const { skus, labelValueSKUs } = useSKUs()
  const [scroll] = useWindowScroll()
  const cardRef = useRef<HTMLDivElement>(null)

  const categories = useCategories()
  const { setQueryParams, getQueryParams } = useQueryParams()

  // FILTER
  const search = getQueryParams('search')
  const selectedCategories = getQueryParams('categories').split(',').filter(Boolean)
  const selectedSKUs = getQueryParams('skus').split(',').filter(Boolean)
  const deliveryDate = getQueryParams('date') ? parseISO(getQueryParams('date')) : null
  const weekDay = String(deliveryDate?.getDay() ?? '')
  const [filterOpened, { toggle: toggleFilter }] = useDisclosure(true)

  const producersWithCategories = useMemo(() => {
    return producers.map((producer) => ({
      ...producer,
      categories: removeDuplicatedValuesFromArray(
        producer.skuCodesReference
          ?.map((code) => String(skus.find((sku) => sku['Código'] === code)?.['Categoria']))
          .filter(Boolean) ?? [],
      ),
    }))
  }, [producers, skus])

  const filteredProducers = useFilterArray(producersWithCategories, [
    {
      type: 'single',
      keys: ['code', 'phone', 'name'],
      value: search,
    },
    {
      type: 'multiple',
      keys: ['categories'],
      value: selectedCategories,
    },
    {
      type: 'multiple',
      keys: ['skuCodesReference'],
      value: selectedSKUs,
      returnEmpty: true,
    },
    {
      type: 'single',
      keys: ['receivingDays'],
      value: weekDay,
    },
  ])

  function changeSearchParams(name: string, value?: string) {
    setQueryParams({
      [name]: value,
    })
  }

  return (
    <Card
      py={{
        base: 'md',
        md: 'xl',
      }}
      px={0}
      shadow="md"
      radius="md"
      ref={cardRef}
    >
      <Box className={styles.fixed_table_name} w={cardRef.current?.offsetWidth ?? 0} top={scroll.y > 0 ? 60 : 80}>
        <Flex justify="space-between" align="center">
          <Title order={3}>Fornecedores</Title>
          <Box hidden={scroll.y > 0}>
            <Flex align="center" gap={16}>
              <ActionIcon
                onClick={toggleFilter}
                loaderProps={{ type: 'dots' }}
                variant={filterOpened ? 'light' : 'subtle'}
                size="lg"
                title="Filtro"
              >
                <IconFilter size={20} />
              </ActionIcon>
              <Button to="/fornecedores/cadastrar" size="compact-md" component={Link}>
                Cadastrar fornecedor
              </Button>
            </Flex>
          </Box>
        </Flex>
      </Box>
      <Box px={25} mt={30}>
        <Collapse in={filterOpened}>
          <Grid grow>
            <Grid.Col
              span={{
                base: 12,
                xs: 6,
              }}
            >
              <Input.Wrapper label="Pesquisar">
                <Input
                  id="search"
                  name="search"
                  placeholder="Pesquise por código, nome ou whatsapp"
                  value={search}
                  leftSection={<IconSearch size={16} />}
                  onChange={(e) => {
                    changeSearchParams(e.target.name, e.target.value)
                  }}
                />
              </Input.Wrapper>
            </Grid.Col>

            <Grid.Col
              span={{
                base: 12,
                xs: 6,
              }}
            >
              <DatePickerInput
                label="Data de entrega"
                placeholder="Selecione uma data"
                locale="pt-br"
                valueFormat="DD/MMM"
                minDate={new Date()}
                clearable
                value={deliveryDate}
                onChange={(date) => {
                  changeSearchParams('date', date?.toISOString())
                }}
              />
            </Grid.Col>

            <Grid.Col
              span={{
                base: 12,
                xs: 6,
              }}
            >
              <MultiSelect
                name="category"
                placeholder="Escolha uma ou mais categorias de produtos"
                label="Categoria"
                data={categories}
                value={selectedCategories}
                searchable
                clearable
                checkIconPosition="right"
                onChange={(values) => {
                  changeSearchParams('categories', values.join(','))
                }}
              />
            </Grid.Col>

            <Grid.Col
              span={{
                base: 12,
                xs: 6,
              }}
            >
              <MultiSelect
                name="skus"
                placeholder="Escolha um ou mais SKUs"
                label="SKUs"
                data={labelValueSKUs}
                value={selectedSKUs}
                searchable
                clearable
                checkIconPosition="right"
                onChange={(values) => {
                  changeSearchParams('skus', values.join(','))
                }}
              />
            </Grid.Col>
          </Grid>
        </Collapse>
      </Box>
      <Space h="md" />
      {!producers.length ? (
        <Center>
          <Text>Você ainda não tem fornecedor cadastrados</Text>
        </Center>
      ) : (
        <CustomTable items={filteredProducers} />
      )}
    </Card>
  )
}
