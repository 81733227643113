import { doc, runTransaction } from 'firebase/firestore'
import db from 'src/configs/firebase'
import { Distributor, Producer, TCreateUpdateProducer } from 'src/types'
import dataConverter from '../utils/dataConverter'

export async function updateProducerAndFixedPrices({ distributorId, fixedPrices, ...data }: TCreateUpdateProducer) {
  await runTransaction(db, async (txn) => {
    const distributorRef = doc(db, 'distributors', distributorId).withConverter(dataConverter<Distributor>())
    const distributorDoc = await txn.get(distributorRef)

    if (!distributorDoc.exists()) {
      throw new Error('Distributor not found')
    }

    const { producers, fixedPrices: distributorFixedPrices } = distributorDoc.data()

    const index = producers.findIndex((producer) => producer.code === data.code)

    if (index === -1) {
      throw new Error('Producer not found')
    }

    const producer = producers[index]

    const restOfFixedPrices = distributorFixedPrices.filter((fixedPrice) => fixedPrice.producerCode !== producer.code)

    const fixedPricesToUpdate = [...restOfFixedPrices, ...fixedPrices]

    const newProducerData: Producer = {
      code: producer.code,
      name: data.name,
      phone: data.phone,
      minimumOrderPrice: data.minimumOrderPrice ?? 0,
      taxpayerIdentificationNumber: data.taxpayerIdentificationNumber,
      address: {
        zipCode: data.address.zipCode,
        street: data.address.street,
        number: data.address.number,
        neighborhood: data.address.neighborhood,
        city: data.address.city,
        state: data.address.state,
        complement: data.address.complement,
      },
      observations: data.observations,
      skuCodesReference: data.skuCodesReference,
      hasInfiniteStock: data.hasInfiniteStock ?? false,
      receivingDays: data.receivingDays,
      rejectionRate: data.rejectionRate,
    }

    // NOTE: Firestore does not support undefined values.
    // If the field is undefined, it should be removed from the object before update.
    if (producer.offersValidForDays) {
      newProducerData.offersValidForDays = producer.offersValidForDays
    }

    if (producer.lastRequest) {
      newProducerData.lastRequest = producer.lastRequest
    }

    producers[index] = newProducerData

    txn.update(distributorRef, {
      producers,
      fixedPrices: fixedPricesToUpdate,
    })
  })
}
