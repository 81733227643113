import type { OrderWithName, ProducerReceivingDays } from 'src/types'

type OrderWithReceivingDays = OrderWithName & {
  receivingDays: ProducerReceivingDays[]
  producerCode: string
}

export default function prepareOrdersTable(Orders: OrderWithReceivingDays[], skuName?: string | number) {
  if (!skuName || !Orders) return []
  const OrdersWithSku = Orders.filter((order) =>
    order.products.find((product) => product.sku === skuName && order.sentOrder.length === 0),
  )

  return OrdersWithSku
}
