import { Unsubscribe, collection, doc, getDoc, limit, onSnapshot, orderBy, query, startAfter } from 'firebase/firestore'
import db from 'src/configs/firebase'
import { useEffect, useState } from 'react'
import { useDistributor } from 'src/providers/Distributor'
import { Channel, Message } from 'src/types'
import converter from 'src/requests/firebase/utils/dataConverter'
import { useErrorBoundary } from 'react-error-boundary'
import { parseAddWhatsAppPhonePrefix } from 'src/utils/parseBrazilianPhones'

export function useChannel(phone?: string) {
  const { distributor } = useDistributor()
  const { showBoundary } = useErrorBoundary()
  const producer = distributor?.producers.find((p) => p.phone === phone)
  const [isLoadingChannel, setIsLoadingChannel] = useState(true)
  const [isLoadingMoreMessages, setIsLoadingMoreMessages] = useState(false)
  const [channel, setChannel] = useState<Channel>({
    producerName: producer?.name ?? '',
    producerPhone: producer?.phone ?? '',
    createdAt: new Date(),
    chatlogs: [],
    updatedAt: new Date(),
  })
  const [page, setPage] = useState(1)
  const [isLastPage, setIsLastPage] = useState(false)
  const [lastChatlog, setLastChatlog] = useState<Message>()

  const increasePage = () => {
    if (isLoadingMoreMessages) return
    setPage((prev) => prev + 1)
    setIsLoadingMoreMessages(true)
  }

  useEffect(() => {
    let unsub: Unsubscribe | undefined
    ;(async () => {
      if (phone && distributor?.distributorId) {
        if (!producer) throw new Error('404 Producer not found')

        const channelRef = doc(
          db,
          'distributors',
          distributor.distributorId,
          'channels',
          parseAddWhatsAppPhonePrefix(phone),
        ).withConverter(converter<Channel>())
        

        const q = query(
          collection(channelRef, 'chatlogs'), 
          orderBy('createdAt', 'desc'), 
          ...(lastChatlog ? [startAfter(lastChatlog?.createdAt)] : []),
          limit(50),
        ).withConverter(
          converter<Message>(),
        )
        const cn = await getDoc(channelRef)

        unsub = onSnapshot(q, (querySnapshot) => {
          try {
            const newChatlogs: Message[] = []
            querySnapshot.forEach((doc) => {
              newChatlogs.push({
                ...doc.data(),
                id: doc.id,
              })
            })

            setIsLastPage(newChatlogs.length < 50 * page)
        
            if (cn.exists()) {
              setChannel((prev) => {
                const uniqueNewChatlogs = newChatlogs.filter(
                  (newLog) => !prev.chatlogs.some((existingLog) => existingLog.id === newLog.id)
                )
                const updatedChatlogs = [...prev.chatlogs, ...uniqueNewChatlogs]
            
                if (page > 1) {
                  setLastChatlog(updatedChatlogs[updatedChatlogs.length - 1])
                } else if (page === 1) {
                  setLastChatlog(newChatlogs[newChatlogs.length - 1])
                }
            
                return {
                  ...prev,
                  chatlogs: updatedChatlogs,
                }
              })
            
              setIsLastPage(newChatlogs.length < 50)
            }
          } catch (error) {
            if (error instanceof Error) {
              console.log(error.message)
              showBoundary(error)
            } else {
              console.log(error)
              showBoundary(error)
            }
          } finally {
            setIsLoadingChannel(false)
            if (page > 1) setIsLoadingMoreMessages(false)
          }
        })
      }
    })()

    return () => {
      if (unsub) {
        unsub()
      }
    }
  }, [phone, distributor?.distributorId, page])

  return {
    channel,
    isLoadingChannel,
    isLoadingMoreMessages,
    loadMoreMessages: () => increasePage(),
    isLastPage,
  }
}
