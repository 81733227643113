import { Routes, Route } from 'react-router-dom'
import PrivatePage from 'src/components/PrivatePage'
import CreateOrUpdateProducer from 'src/components/Producer/CreateOrUpdateProducer'
import ProducersTable from 'src/components/Producer/ProducersTable'

export default function Producers() {
  return (
    <PrivatePage>
      <Routes>
        <Route index element={<ProducersTable />} />
        <Route path="cadastrar" element={<CreateOrUpdateProducer />} />
        <Route path="editar/:phone" element={<CreateOrUpdateProducer />} />
      </Routes>
    </PrivatePage>
  )
}
