import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Flex, Modal, Text } from '@mantine/core'
import { FieldPath, useFieldArray, useForm } from 'react-hook-form'
import { useFixedPrices } from 'src/hooks/useFixedPrices'
import { useSKUs } from 'src/hooks/useSKUs'
import { ItemsArray } from '../Offers/CreateOffer/ItemsArray'
import { useProducers } from 'src/hooks/useProducers'
import { INFINITE_STOCK } from 'src/constants/products'
import { TCreateOffer } from 'src/types'
import { useEffect } from 'react'
import * as yup from 'yup'
import { SelectedOfferToAddSkuData } from './Offers'
import { addDays, startOfDay } from 'date-fns'

type AddSkuModalProps = {
  onCloseModal(): void
  handleAdd: (products: TCreateOffer['products']) => Promise<void> | void
  show: boolean
  producerPhone: string
  selectedOfferToAddSku: SelectedOfferToAddSkuData | null
}

const addSkuSchema = yup.object().shape({
  phone: yup.string().required('O campo "Fornecedor" é obrigatório.'),
  products: yup
    .array(
      yup.object().shape({
        sku: yup.string().required('O campo "SKU" é obrigatório.'),
        volume: yup
          .number()
          .when('$hasInfiniteStock', ([hasInfiniteStock], field) => {
            if (hasInfiniteStock) {
              return field
            }

            return field.min(1, 'O campo "Volume" precisa ser um número positivo.')
          })
          .required('O campo "Volume" é obrigatório.'),
        price: yup.number().required(),
        unit: yup.string().required(),
      }),
    )
    .required(),
  deliveryDate: yup.date().required('O campo "Data de entrega" é obrigatório.'),
})

export type AddSkuFormType = yup.InferType<typeof addSkuSchema>

export default function AddSkuModal({
  handleAdd,
  onCloseModal = () => {},
  show = false,
  producerPhone,
  selectedOfferToAddSku,
}: AddSkuModalProps) {
  const { producers } = useProducers()
  const selectedDate = startOfDay(addDays(selectedOfferToAddSku?.deliveryDate ?? new Date(), 1))
  const producer = producers.find((producer) => producer.phone === producerPhone)

  const { handleSubmit, formState, control, setValue, register, trigger, getValues, reset } = useForm<AddSkuFormType>({
    resolver: yupResolver(addSkuSchema),
    context: {
      hasInfiniteStock: producer?.hasInfiniteStock,
    },
    defaultValues: {
      phone: producerPhone,
      deliveryDate: selectedDate,
      products: [
        {
          sku: '',
          volume: 0,
          price: 0,
          unit: '',
        },
      ],
    },
  })

  const fieldArray = useFieldArray({
    name: 'products',
    control,
  })

  const { stringNameSKUs, skus } = useSKUs()

  const fixedPrices = useFixedPrices({
    producerCode: producer?.code,
  })

  function onChangeSku({ index, value }: { index: number; value: string | null }) {
    const sku = skus.find((s) => s['SKU'] === value)

    if (sku) {
      setValue(`products.${index}.unit`, String(sku['Medida']))

      const price = fixedPrices.find((p) => p.sku === sku['Código'] && producer?.code === p.producerCode)

      if (price) {
        setValue(`products.${index}.price`, price.fixedPrice)
      }
    }
  }

  const { errors } = formState

  useEffect(() => {
    if (producer?.hasInfiniteStock && errors.products) {
      const volumeErrorsPaths = (errors.products
        .map?.((err, idx) => (err?.volume ? `products.${idx}.volume` : null))
        .filter(Boolean) ?? []) as FieldPath<AddSkuFormType>[]

      trigger(volumeErrorsPaths)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [producer?.hasInfiniteStock])

  useEffect(() => {
    reset({
      phone: producerPhone,
      deliveryDate: selectedDate,
      products: [
        {
          sku: '',
          volume: 0,
          price: 0,
          unit: '',
        },
      ],
    })
  }, [onCloseModal])

  async function onSubmitForm() {
    const productsWithSanitizedVolume = getValues('products').map((p) => {
      const producerHasInfiniteStock = producer?.hasInfiniteStock

      if (producerHasInfiniteStock) {
        return {
          ...p,
          volume: p.volume ? p.volume : INFINITE_STOCK,
        }
      }

      return p
    })

    await handleAdd(productsWithSanitizedVolume)
  }

  return (
    <Modal opened={show} onClose={onCloseModal} title={<Text fw={600}>Adicionar SKUs</Text>} size={'xl'}>
      <Box>
        <ItemsArray
          control={control}
          fieldArray={fieldArray}
          stringNameSKUs={stringNameSKUs}
          register={register}
          onChangeSku={onChangeSku}
        />
        <Flex justify="space-between" mt="md">
          <Button fw={500} variant="outline" color="gray" c="black" onClick={onCloseModal}>
            Cancelar
          </Button>
          <Button type="submit" onClick={handleSubmit(onSubmitForm)}>
            Adicionar
          </Button>
        </Flex>
      </Box>
    </Modal>
  )
}
