import { Box, Button, Card, Center, Flex, Space, Stack, Table, Text, Title } from '@mantine/core'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { IconFileUpload } from '@tabler/icons-react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { pendingNotificationFactory } from 'src/factories/pendingNotificationFactory'
import { useDistributor } from 'src/providers/Distributor'
import { useOrders } from 'src/providers/Orders'
import importOrders from 'src/requests/importOrders'
import { Distributor, OrderImport } from 'src/types'
import { formatUTCDateAndWeekDay } from 'src/utils/dates/formatDateAndWeekDay'
import formatBrazilianNumber from 'src/utils/formatBrazilianNumber'
import getOrdersWithSuppliers from 'src/utils/getOrdersWithSuppliers'
import { ImportFromCSVModal } from '../common/Modals/ImportModal'
import styles from './OrdersTable.module.css'

export default function OrdersTable() {
  const [importModalOpened, importModalHandlers] = useDisclosure(false)
  const [importOrdersData, setImportOrdersData] = useState<OrderImport[]>([])
  const { distributor } = useDistributor()
  const { orders } = useOrders()
  const ordersWithNames = getOrdersWithSuppliers(orders, distributor).filter((order) => order.draftOrder.length > 0)
  const isMobile = useMediaQuery('(max-width: 768px)')
  const cardPadding = isMobile ? 'md' : 'xl'

  return (
    <Card padding={cardPadding} shadow="md" radius="md" className={styles.card}>
      <ImportFromCSVModal
        title="Importar pedidos"
        opened={importModalOpened}
        onClose={importModalHandlers.close}
        onLoadData={setImportOrdersData}
        data={importOrdersData}
        onConfirm={(send: boolean) => {
          onConfirmImportOrders({
            send,
            importModalHandlers,
            setImportOrdersData,
            importOrdersData,
            distributor,
          })
        }}
      />
      <Box className={styles.header}>
        <Flex align="center" justify="space-between">
          <Title order={3}>Pedidos abertos</Title>
          {import.meta.env.VITE_FEAT_IMPORT_ORDERS === 'true' && (
            <Button
              onClick={importModalHandlers.open}
              size="sm"
              variant="default"
              radius="sm"
              leftSection={<IconFileUpload strokeWidth={1.5} size={18} />}
            >
              Importar
            </Button>
          )}
        </Flex>
      </Box>
      <Space h="md" />
      {!ordersWithNames || ordersWithNames?.length === 0 ? (
        <Center>
          <Text>Você ainda não tem pedidos abertos</Text>
        </Center>
      ) : (
        <Table.ScrollContainer minWidth={300}>
          <Table withRowBorders={false} verticalSpacing="sm">
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Entrega</Table.Th>
                <Table.Th>Nome</Table.Th>
                <Table.Th>Telefone</Table.Th>
                <Table.Th>SKUs no pedido</Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {ordersWithNames.map((order) => {
                const id = order.id
                const name = order.name
                const deliveryDate = order.deliveryDate
                const { date, weekDay } = formatUTCDateAndWeekDay(deliveryDate)
                const phone = formatBrazilianNumber(order.phone).slice(3)
                const draftOrder = order.draftOrder
                const skusInDraftOrder = draftOrder.map((order) => order.sku).join(', ') || 'Nenhum'
                const hasDraftOrder = draftOrder.length > 0
                return (
                  <Table.Tr key={id}>
                    <Table.Td>
                      <Stack gap={0}>
                        <Text size="sm">{date}</Text>
                        <Text size="xs">{weekDay}</Text>
                      </Stack>
                    </Table.Td>
                    <Table.Td>
                      <Button p="0" component={Link} to={`/mensagens/${order.phone}`} variant="transparent">
                        {name}
                      </Button>
                    </Table.Td>
                    <Table.Td>{phone}</Table.Td>
                    <Table.Td>{skusInDraftOrder}</Table.Td>
                    <Table.Td>
                      <Link to={id}>
                        <Button disabled={!hasDraftOrder}>
                          {hasDraftOrder ? 'Continuar pedido' : 'Nenhum pedido'}
                        </Button>
                      </Link>
                    </Table.Td>
                  </Table.Tr>
                )
              })}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
      )}
    </Card>
  )
}

interface ImportOrdersProps {
  send: boolean
  distributor: Distributor | null
  importOrdersData: OrderImport[]
  setImportOrdersData: React.Dispatch<React.SetStateAction<OrderImport[]>>
  importModalHandlers: {
    open: () => void
    close: () => void
  }
}

async function onConfirmImportOrders({
  send,
  importModalHandlers,
  setImportOrdersData,
  importOrdersData,
  distributor,
}: ImportOrdersProps) {
  const notification = pendingNotificationFactory({
    pending: {
      title: 'Criando pedidos...',
      message: 'Isso não deve demorar muito',
    },
    error: {
      title: 'Falha ao criar pedidos',
      message: 'Por favor, verifique os dados, caso o problema persista, entre em contato com o administrador.',
    },
    success: {
      title: 'Pedidos criada com sucesso!',
      message: 'Caso a lista não atualize, por favor, atualize manualmente',
    },
  })

  try {
    await importOrders({
      distributorId: distributor!.distributorId,
      orders: importOrdersData,
      send,
    })

    notification.success()
    setImportOrdersData([])
    importModalHandlers.close()
  } catch (error) {
    notification.error()
    if (error instanceof Error) {
      console.error(error.message)
    } else {
      console.error(error)
    }
  }
}
