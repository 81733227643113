import ReactDOM from 'react-dom/client'
import Router from 'src/providers/Router'
import Kinde from 'src/providers/Kinde'
import Mantine from 'src/providers/Mantine'
import ErrorBoundary from 'src/components/ErrorBoundary'
import FirebaseAuth from 'src/providers/FirebaseAuth'
import Distributor from 'src/providers/Distributor'
import Orders from 'src/providers/Orders'
import './index.css'
import '@mantine/core/styles.css'
import '@mantine/dates/styles.css'
import '@mantine/notifications/styles.css';
import '@fontsource/work-sans/300.css'
import '@fontsource/work-sans/400.css'
import '@fontsource/work-sans/500.css'
import '@fontsource/work-sans/600.css'
import '@fontsource/work-sans/700.css'
import '@fontsource/work-sans/900.css'
import App from './App'
import Axios from './providers/Axios/Axios.tsx'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import '@mantine/dropzone/styles.css'
import 'dayjs/locale/pt-br'
import * as Sentry from "@sentry/react"
import { useEffect } from "react"
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom"

const queryClient = new QueryClient()

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  environment: import.meta.env.VITE_SENTRY_ENV,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  normalizeDepth: 6,
})

ReactDOM.createRoot(document.getElementById('root')!).render(
    <Router>
      <Kinde>
        <Mantine>
          <ErrorBoundary>
            <FirebaseAuth>
              <Axios>
                <QueryClientProvider client={queryClient}>
                  <Distributor>
                    <Orders>
                      <App />
                    </Orders>
                  </Distributor>
                </QueryClientProvider>
              </Axios>
            </FirebaseAuth>
          </ErrorBoundary>
        </Mantine>
      </Kinde>
    </Router>
)
