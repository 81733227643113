import { arrayRemove, doc, runTransaction } from 'firebase/firestore'
import db from 'src/configs/firebase'
import { Distributor, TDeleteProducer } from 'src/types'
import dataConverter from '../utils/dataConverter'

export async function deleteProducerAndFixedPrices({ distributorId, ...data }: TDeleteProducer) {
  await runTransaction(db, async (txn) => {
    const distributorRef = doc(db, 'distributors', distributorId).withConverter(dataConverter<Distributor>())

    const distributorSnapshot = await txn.get(distributorRef)

    const distributorDoc = distributorSnapshot.data()

    if (!distributorDoc) {
      throw new Error('Distributor not found')
    }

    const producerFixedPrices = distributorDoc.fixedPrices.filter((fixedPrice) => fixedPrice.producerCode === data.code)

    txn.update(distributorRef, {
      producers: arrayRemove(data),
      fixedPrices: arrayRemove(...producerFixedPrices),
    })
  })
}
