import { useKindeAuth } from '@kinde-oss/kinde-auth-react'
import { useEffect } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { api } from 'src/services/api'
import type { AxiosProps } from 'src/types'
import { pendingNotificationFactory } from 'src/factories/pendingNotificationFactory'
import { useLogout } from 'src/hooks/useLogout'
import { DEFAULT_AUTO_CLOSE_TIMEOUT } from 'src/constants/pedingNotification'
import type { NotificationMessage } from 'src/types'

let dispatchedNotifications = {
  isAccessTokenExpired: false,
}

const createErrorNotification = (context: NotificationMessage) => {
  const notification = pendingNotificationFactory({
    pending: {
      title: '',
      message: '',
    },
    success: {
      title: '',
      message: '',
    },
    error: context,
  })

  return {
    dispatch: () => notification.error(context),
  }
}

const dispatchAccessTokenExpiredNotification = () => {
  const notification = createErrorNotification({
    title: 'Sessão expirada',
    message: 'Você será direcionado para o login em instantes',
  })
  dispatchedNotifications.isAccessTokenExpired = true
  notification.dispatch()
}

const handleHttpError = (error: any, logout: () => Promise<void>) => {
  const hasResponse = !!error.response

  if (!hasResponse) {
    return Promise.reject(error)
  }

  const { response } = error

  const isAccessTokenExpired = response.status === 403 && response.headers.get('Location')

  // Prevent notification spam when user keeps receiving 403 errors while navigating and waiting `useLogout` to run.
  if (isAccessTokenExpired && !dispatchedNotifications.isAccessTokenExpired) {
    dispatchAccessTokenExpiredNotification()
    setTimeout(() => logout(), DEFAULT_AUTO_CLOSE_TIMEOUT)

    return
  }

  return Promise.reject(error)
}

export default function Axios({ children }: AxiosProps) {
  const { getToken, isAuthenticated, isLoading, user } = useKindeAuth()
  const { showBoundary } = useErrorBoundary()
  const { logout } = useLogout()

  useEffect(() => {
    let interceptor = null
    ;(async () => {
      if (isAuthenticated && !isLoading) {
        try {
          const token = await getToken()

          interceptor = api.interceptors.request.use((config) => {
            const updatedConfig = { ...config }

            if (token) {
              updatedConfig.headers['authorization'] = `Bearer ${token}`
            }

            return updatedConfig
          })

          api.interceptors.response.use(
            (response) => response,
            (error) => handleHttpError(error, logout),
          )
        } catch (error) {
          if (error instanceof Error) {
            console.log(error.message)
            showBoundary(error)
          } else {
            console.log(error)
            showBoundary(error)
          }
        }
      }
    })()

    if (interceptor !== null) return api.interceptors.request.eject(interceptor)
  }, [isAuthenticated, isLoading, user, getToken, showBoundary])

  return children
}
