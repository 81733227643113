import React, { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { TextInput, Button, Box, SimpleGrid } from '@mantine/core'
import { TCreateOrUpdateSKUProps } from 'src/types'
import { useDistributor } from 'src/providers/Distributor'
import { uppercaseTransform } from 'src/utils/transformObjectUppercase'
import SearchableSelect from '../SearchableSelect'
import { removeDuplicatedValuesFromArray } from 'src/utils/removeDuplicatedValuesFromArray'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const SKUSchema = yup
  .object()
  .shape({
    Código: yup
      .string()
      .required('O campo "Código" é obrigatório')
      .min(3, 'O campo "Código" deve ter no mínimo 3 caracteres.')
      .test('duplicated_code', 'Já existe um SKU com esse código', (value, { options }) => {
        if (!value) return true

        const currentSKUsCodes: string[] = options.context!.currentSKUsCodes
        const isEditing: boolean = options.context!.isEditing
        const isSame = currentSKUsCodes.includes(value.toUpperCase())

        if (!isEditing && isSame) {
          return false
        }

        return true
      }),
    SKU: yup.string().required('O campo "SKU" é obrigatório.'),
    Categoria: yup.string().required('O campo "Categoria" é obrigatório.'),
    Medida: yup
      .string()
      .required('O campo "Un de Medida" é obrigatório.')
      .max(2, 'O campo "Un de medida" deve ter no máximo 2 caracteres.'),
  })
  .transform(uppercaseTransform)

type schemaType = yup.InferType<typeof SKUSchema>

type SKUFormProps = {
  onSubmit: (data: TCreateOrUpdateSKUProps) => Promise<void>
  defaultValues?: TCreateOrUpdateSKUProps
}

const SKUForm: React.FC<SKUFormProps> = ({ onSubmit, defaultValues }) => {
  const { distributor } = useDistributor()

  const skus = useMemo(() => distributor?.skus ?? [], [distributor?.skus])

  const currentSKUsCodes = distributor?.skus.map((sku) => sku['Código']) ?? []

  const { register, handleSubmit, formState, control } = useForm<schemaType>({
    resolver: yupResolver(SKUSchema),
    defaultValues,
    context: {
      currentSKUsCodes,
      isEditing: !!defaultValues,
    },
  })

  const measurementUnits = useMemo(() => {
    return removeDuplicatedValuesFromArray(skus.map((sku) => String(sku.Medida)))
  }, [skus])

  const categories = useMemo(() => {
    return removeDuplicatedValuesFromArray(skus.map((sku) => String(sku.Categoria)))
  }, [skus])

  const { errors } = formState

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SimpleGrid
        cols={{
          base: 1,
          xs: 2,
        }}
      >
        <Box>
          <TextInput
            disabled={!!defaultValues || formState.isSubmitting}
            label="Código"
            {...register('Código', { required: true })}
            error={errors['Código']?.message}
          />
        </Box>
        <Box>
          <TextInput
            disabled={!!defaultValues || formState.isSubmitting}
            label="SKU"
            {...register('SKU', { required: true })}
            error={errors.SKU?.message}
          />
        </Box>
        <Box>
          <Controller
            control={control}
            name="Medida"
            render={({ field, fieldState }) => (
              <SearchableSelect
                label="Un de Medida"
                onChange={field.onChange}
                value={field.value}
                options={measurementUnits}
                error={fieldState.error?.message}
              />
            )}
          />
        </Box>
        <Box>
          <Controller
            control={control}
            name="Categoria"
            render={({ field, fieldState }) => (
              <SearchableSelect
                label="Categoria"
                onChange={field.onChange}
                value={field.value}
                options={categories}
                error={fieldState.error?.message}
              />
            )}
          />
        </Box>
      </SimpleGrid>
      <Button mt="xl" type="submit" loading={formState.isSubmitting}>
        Salvar
      </Button>
    </form>
  )
}

export default SKUForm
