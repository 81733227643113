import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import ErrorMessage from 'src/components/ErrorMessage'
import type { ErrorBoundaryProps } from 'src/types.ts'
import { captureException } from 'src/utils/captureException'

export default function ErrorBoundary({ children }: ErrorBoundaryProps) {
  return (
    <ReactErrorBoundary
      fallbackRender={ErrorMessage}
      onError={(error, info) => {
        captureException(error, {
          data: info,
        })
      }}
    >
      {children}
    </ReactErrorBoundary>
  )
}
