import { api } from 'src/services/api'

type RequestOfferContext = {
  distributorId: string
  phone: string
  date: Date
  products: string[]
}

export default async function requestOffer({ phone, ...body }: RequestOfferContext) {
  await api.post(`/producers/${phone}/offers/request`, body)
}
