import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Divider, Grid, InputDescription, Select, Text } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { addDays, startOfDay } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { Controller, FieldPath, useFieldArray, useForm } from 'react-hook-form'
import { INFINITE_STOCK } from 'src/constants/products'
import { useFixedPrices } from 'src/hooks/useFixedPrices'
import { useProducers } from 'src/hooks/useProducers'
import { useSKUs } from 'src/hooks/useSKUs'
import { TCreateOffer } from 'src/types'
import * as yup from 'yup'
import { ItemsArray } from './ItemsArray'

export const offerSchema = yup.object().shape({
  phone: yup.string().required('O campo "Fornecedor" é obrigatório.'),
  products: yup
    .array(
      yup.object().shape({
        sku: yup.string().required('O campo "SKU" é obrigatório.'),
        volume: yup
          .number()
          .when('$hasInfiniteStock', ([hasInfiniteStock], field) => {
            if (hasInfiniteStock) {
              return field
            }

            return field.min(1, 'O campo "Volume" precisa ser um número positivo.')
          })
          .required('O campo "Volume" é obrigatório.'),
        price: yup.number().required(),
        unit: yup.string().required(),
      }),
    )
    .required(),
  deliveryDate: yup.date().required('O campo "Data de entrega" é obrigatório.'),
})

export type AddOfferFormType = yup.InferType<typeof offerSchema>

type OfferFormProps = {
  onSubmit(data: TCreateOffer): void | Promise<void>
}

export function OfferForm({ onSubmit }: OfferFormProps) {
  const [selectedProducerPhone, setSelectedProducerPhone] = useState('')

  const { producers, labelValueProducers } = useProducers()

  const selectedProducer = useMemo(() => {
    return producers.find((p) => p.phone === selectedProducerPhone)
  }, [selectedProducerPhone, producers])

  const fixedPrices = useFixedPrices({
    producerCode: selectedProducer?.code,
  })

  const { handleSubmit, formState, control, setValue, trigger, register } = useForm<AddOfferFormType>({
    resolver: yupResolver(offerSchema),
    context: {
      hasInfiniteStock: selectedProducer?.hasInfiniteStock,
    },
    defaultValues: {
      phone: '',
      deliveryDate: startOfDay(addDays(new Date(), 1)),
      products: [
        {
          sku: '',
          volume: 0,
          price: 0,
          unit: '',
        },
      ],
    },
  })
  const fieldArray = useFieldArray({
    name: 'products',
    control,
  })

  const { stringNameSKUs, skus } = useSKUs()

  async function onSubmitForm(data: AddOfferFormType) {
    const productsWithSanitizedVolume = data.products.map((p) => {
      const producerHasInfiniteStock = selectedProducer?.hasInfiniteStock

      if (producerHasInfiniteStock) {
        return {
          ...p,
          volume: p.volume ? p.volume : INFINITE_STOCK,
        }
      }

      return p
    })

    await onSubmit({
      phone: data.phone,
      products: productsWithSanitizedVolume,
      deliveryDate: data.deliveryDate,
    })
  }

  function onChangeSku({ index, value }: { index: number; value: string | null }) {
    const sku = skus.find((s) => s['SKU'] === value)

    if (sku) {
      setValue(`products.${index}.unit`, String(sku['Medida']))

      const price = fixedPrices.find((p) => p.sku === sku['Código'] && selectedProducer?.code === p.producerCode)

      if (price) {
        setValue(`products.${index}.price`, price.fixedPrice)
      }
    }
  }

  const { errors } = formState

  useEffect(() => {
    if (selectedProducer?.hasInfiniteStock && errors.products) {
      const volumeErrorsPaths = (errors.products
        .map?.((err, idx) => (err?.volume ? `products.${idx}.volume` : null))
        .filter(Boolean) ?? []) as FieldPath<AddOfferFormType>[]

      trigger(volumeErrorsPaths)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProducer?.hasInfiniteStock])

  useEffect(() => {
    if (selectedProducer?.offersValidForDays) {
      setValue('deliveryDate', startOfDay(addDays(new Date(), selectedProducer.offersValidForDays)))
    }
  }, [selectedProducer?.offersValidForDays])

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Grid grow>
        <Grid.Col span={{ base: 12, xs: 6 }}>
          <Controller
            control={control}
            name="phone"
            render={({ field: { onChange, ...field }, fieldState }) => (
              <Select
                label="Fornecedor"
                allowDeselect={false}
                placeholder="Selecione um"
                searchable
                checkIconPosition="right"
                data={labelValueProducers}
                error={fieldState.error?.message}
                onChange={(val) => {
                  setSelectedProducerPhone(val ?? '')
                  return onChange(val)
                }}
                {...field}
              />
            )}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, xs: 6 }}>
          <Controller
            control={control}
            name="deliveryDate"
            render={({ field, fieldState }) => (
              <DatePickerInput
                disabled={!!selectedProducer?.offersValidForDays}
                label={selectedProducer?.offersValidForDays ? 'Data de validade' : 'Data de entrega'}
                placeholder="Selecione uma data"
                locale="pt-br"
                valueFormat="DD/MMM"
                minDate={new Date()}
                clearable
                error={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </Grid.Col>
      </Grid>

      <Divider mt="xl" mb="lg" />
      <Box mb="sm">
        <Text fw={500}>Itens</Text>
        <InputDescription>Para itens com volume 0 será considerado volume infinito, se definido.</InputDescription>
      </Box>

      <ItemsArray
        control={control}
        fieldArray={fieldArray}
        stringNameSKUs={stringNameSKUs}
        register={register}
        onChangeSku={onChangeSku}
      />

      <Button mt="xl" type="submit" loading={formState.isSubmitting}>
        Salvar
      </Button>
    </form>
  )
}
