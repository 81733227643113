import { useCallback, useMemo } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { OrdersProvider } from './OrdersContextValue'
import { useKindeAuth } from '@kinde-oss/kinde-auth-react'
import useFirebaseUser from 'src/hooks/useFirebaseUser'
import { useFirebaseAuth } from 'src/providers/FirebaseAuth'
import type { OrdersProps, Orders, OrderWithMetrics } from 'src/types'
import cancelOrder from 'src/requests/firebase/draftOrder/cancelOrder'
import sendCancelOrderToBackend from 'src/requests/sendCancelOrderToBackend'
import { notifications } from '@mantine/notifications'
import { IconCheck, IconX } from '@tabler/icons-react'
import { AxiosError } from 'axios'
import { getActiveOrders } from 'src/requests/orders/getActiveOrders'
import { useQuery } from '@tanstack/react-query'
import { eachDayOfInterval, isSameDay } from 'date-fns'
import { UTCDate } from '@date-fns/utc'
import { enforceUTCDate } from 'src/utils/formatDate'

const ORDERS_QUERY_KEY = ['orders', 'active']

export default function Orders({ children }: OrdersProps) {
  const { showBoundary } = useErrorBoundary()
  const {
    user: kindeUser,
    getOrganization,
    isAuthenticated: isKindeAuthenticated,
    isLoading: isKindeLoading,
  } = useKindeAuth()
  const { firebaseUser } = useFirebaseUser()
  const { isAuthenticating } = useFirebaseAuth()

  const onCancelOrder = useCallback(
    async (orderId: string) => {
      const notificationId = notifications.show({
        loading: true,
        title: 'Cancelando pedido...',
        message: 'Isso não deve demorar muito',
        autoClose: false,
        withCloseButton: false,
      })
      try {
        const organization = getOrganization()
        const distributorId = organization.orgCode
        if (!distributorId) throw new Error('distributorId not found')

        await sendCancelOrderToBackend({ orderId, distributorId })
        await cancelOrder({ distributorId, orderId })
        notifications.update({
          id: notificationId,
          color: 'teal',
          title: 'Pedido cancelado com sucesso!',
          message: 'Caso a lista não atualize, por favor, atualize manualmente',
          icon: <IconCheck size={18} />,
          loading: false,
          autoClose: 4000,
          withCloseButton: true,
        })
      } catch (error) {
        if (error instanceof AxiosError) {
          notifications.update({
            id: notificationId,
            color: 'red',
            title: 'Erro ao cancelar pedido.',
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            message: error.response?.data?.message ?? 'Ocorreu um erro inesperado, por favor, tente novamente.',
            icon: <IconX size={18} />,
            loading: false,
            autoClose: 4000,
            withCloseButton: true,
          })
        } else {
          console.log(error)
          showBoundary(error)
          notifications.update({
            id: notificationId,
            color: 'red',
            title: 'Erro ao cancelar pedido.',
            message: 'Ocorreu um erro inesperado, por favor, tente novamente.',
            icon: <IconX size={18} />,
            loading: false,
            autoClose: 4000,
            withCloseButton: true,
          })
        }
      }
    },
    [getOrganization, showBoundary],
  )

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ORDERS_QUERY_KEY,
    queryFn: getActiveOrders,
    enabled: !isAuthenticating && !!kindeUser && !!firebaseUser && isKindeAuthenticated && !isKindeLoading,
    throwOnError: true,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  const duplicatedOrdersWithExpirationDate = useMemo(() => {
    if (!data) return []

    return makeOrdersWithExpirationDate(data)
  }, [data])

  async function setShouldRefetch(bool: boolean) {
    if (!bool) return

    await refetch()
  }

  const contextValue = {
    isLoading,
    isFetching,
    orders: data ?? null,
    duplicatedOrdersWithExpirationDate,
    setShouldRefetch,
    onCancelOrder,
  }

  return <OrdersProvider value={contextValue}>{children}</OrdersProvider>
}

function makeOrdersWithExpirationDate(orders: OrderWithMetrics[]) {
  return orders.flatMap((order, index) => {
    if (!order.validUntil) return [order]

    const days = eachDayOfInterval({
      start: new UTCDate(enforceUTCDate(new Date())),
      end: new UTCDate(order.validUntil),
    })

    const filteredDays = days.filter((day) => {
      const alreadyExists = orders.some((o, idx) => {
        const samePhone = o.phone === order.phone
        const sameDeliveryDate = isSameDay(day.toISOString(), o.deliveryDate)
        const notSent = !o.sentAt
        const notSameOrder = index !== idx

        return samePhone && sameDeliveryDate && notSent && notSameOrder
      })

      return !alreadyExists
    })

    return filteredDays.map((day) => ({
      ...order,
      deliveryDate: day,
    }))
  })
}
