import { Distributor, TCreateUpdateProducer } from 'src/types'
import db from 'src/configs/firebase'
import { arrayUnion, doc, updateDoc } from 'firebase/firestore'
import dataConverter from '../utils/dataConverter'

export async function createProducerAndFixedPrices({ distributorId, fixedPrices, ...data }: TCreateUpdateProducer) {
  const distributorRef = doc(db, 'distributors', distributorId).withConverter(dataConverter<Distributor>())

  await updateDoc(distributorRef, {
    producers: arrayUnion(data),
    fixedPrices: arrayUnion(...fixedPrices),
  })
}
